@import "../../../../resources/styles/base-colors.less";
@import "../../../../resources/styles/colors.less";
@import "../../../../resources/styles/media.less";

.branchSelectorContainer {
    display: flex;
}

.branchSelectorAndContent {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.selectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.childContent {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-around;
    padding: 0.125rem;
    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    // This prevents the child from growing larger than the parent flexbox container if the childs content is large
    min-width: 0;
}